import { graphql } from 'gatsby'
import React from 'react'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Markdown from 'components/Markdown'
import styles from './Page.module.scss'

type Props = {
  data: unknown
}

export default function Page({ data }: Props) {
  const { mdx } = data
  const { frontmatter: cmsData, body: cmsDataContent } = mdx

  return (
    <Layout className="Page">
      <Head {...cmsData} />
      <div className={styles.content}>
        <Container size="xlarge" className={styles.container}>
          <h1>{cmsData.title}</h1>
          <Markdown>{cmsDataContent}</Markdown>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Page($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        #metadata {
        #  description
        #}
        title
      }
      body
    }
  }
`
